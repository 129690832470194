import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("conceptos_nominas/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("conceptos_nominas/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("conceptos_nominas", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("conceptos_nominas/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("conceptos_nominas", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getCodigo(parametros) {
        return Vue.axios.post("conceptos_nominas/codigo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
    
}