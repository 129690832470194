<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Conceptos de nómina'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="6" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Código"
                                    v-model="codigo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo Catálogo SAT"
                                    :items="tipoCatalogoSAT"
                                    item-text="nombre"
                                    item-value="valor"
                                    persistent-hint
                                    v-model="tipoSAT"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Descripción"
                                    v-model="descripcion"
                                ></v-text-field>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.codigo}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
            <template>
                <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <!-- <v-col v-if="rol=='root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="conceptoNom.cliente_id"
                                                    class="pa-0 ma-0"
                                                >
                                                </v-autocomplete>
                                            </v-col> -->
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo Catálogo SAT" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo Catálogo SAT"
                                                        :items="tipoCatalogoSAT"
                                                        item-text="nombre"
                                                        item-value="valor"
                                                        persistent-hint
                                                        v-model="tipoSAT"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Código SAT" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Código SAT"
                                                        :items="itemsCatalogo"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="codigo_sat_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Código" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Código" 
                                                        class="pa-0 ma-0" 
                                                        v-model="conceptoNom.codigo" 
                                                        :error-messages="errors" 
                                                        required
                                                    >                                                    
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-text-field 
                                                    outlined
                                                    label="Porcentaje" 
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    suffix="%"
                                                    class="pa-0 ma-0"
                                                    onkeyup="if(this.value > 100 || this.value<0) this.value = 0;"
                                                    v-model="conceptoNom.porcentaje"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Descripción" 
                                                        class="pa-0 ma-0" 
                                                        v-model="conceptoNom.descripcion" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Fórmula" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Fórmula"
                                                        :items="itemsFormulas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="conceptoNom.formula_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12">
                                                <v-container fluid>
                                                    <v-switch class="pa-0 ma-0" v-model="conceptoNom.graba_imss" label="Gravar IMSS"></v-switch>
                                                    <v-switch class="pa-0 ma-0" v-model="conceptoNom.graba_isr" label="Gravar ISR"></v-switch>
                                                    <v-switch class="pa-0 ma-0" v-model="conceptoNom.graba_impuesto_estatal" label="Graba impuesto estatal"></v-switch>
                                                </v-container>
                                            </v-col>

                                            <v-divider></v-divider>
                                            <h3 class="subtitle mt-5 ml-2">Campos</h3>

                                            <v-row class="pa-0">
                                                <v-col cols="12" xs="12" sm="3" md="3" class="my-2">
                                                    <v-text-field
                                                        outlined
                                                        label="Nombre"
                                                        v-model="nombre"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="3" md="3" class="mt-2">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo"
                                                        :items="itemsTiposCampos"
                                                        item-text="nombre"
                                                        item-value="valor"
                                                        persistent-hint
                                                        v-model="tipo"
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="3" md="3" class="mt-2">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Código"
                                                        :items="itemsCodigos"
                                                        item-text="nombre"
                                                        item-value="valor"
                                                        persistent-hint
                                                        v-model="codigo"
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="2" md="2" class="my-2">
                                                    <v-text-field 
                                                        outlined
                                                        label="Orden" 
                                                        type="number"
                                                        min="0"
                                                        max="100"
                                                        v-model="orden"
                                                        onkeyup="if(this.value > 100 || this.value<0) this.value = 0;"
                                                    >
                                                    </v-text-field>
                                                </v-col>

                                                <v-btn color="#1E2245" elevation="0" class="mt-6" dark fab  @click="agregarCampo()" small>
                                                    <v-icon dark>add</v-icon>
                                                </v-btn>
                                                
                                                <template v-for="(i,index) in conceptoNom.campos">
                                                    <v-col cols="12" xs="12" sm="12" md="3" :key="i.id" class="pt-0 pb-0 ma-0">
                                                        <v-text-field id="file_input_file" label="Nombre" outlined disabled type="text" :value="i.nombre"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="5" md="3" :key="'A'+i.id" class="pt-0 pb-0 ma-0">
                                                        <v-text-field type="text" label="Tipo" outlined :value="i.tipo_campo.nombre" disabled ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="5" md="3" :key="'B'+i.id" class="pt-0 pb-0 ma-0">
                                                        <v-text-field type="text" label="Código" outlined :value="i.codigoNombre" disabled ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="5" md="2" :key="'C'+i.id" class="pt-0 pb-0 ma-0">
                                                        <v-text-field type="text" label="Orden" outlined :value="i.orden" disabled ></v-text-field>
                                                    </v-col>
                                                    <v-btn class="botonHover mt-1" :key="'D'+i.id" icon fab dark small @click="eliminaCampo(index, i.id)" >
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-row>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/ConceptoNomina';
import queriesFormulas from '@/queries/Formulas';
import Notify from '@/plugins/notify';
import apiPercepcion from '@/api/nominas/tipoPercepcion';
import apiDeduccion from '@/api/nominas/tipoDeduccion';
import apiOtroPago from '@/api/nominas/tipoOtroPago';
import apiConceptoNomina from '@/api/conceptoNomina';
import apiClientes from "@/api/clientes";

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Código',
                    name: 'codigo',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            isLoadingModal  : false,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            conceptoNom      : {
                id              : null,
                cliente_id      : null,
                codigo          : null,
                codigo_sat_id   : null,
                tipo            : null,
                descripcion     : null,
                porcentaje      : 0,
                formula_id      : null,
                graba_imss      : false,
                graba_isr       : false,
                graba_impuesto_estatal: false,
                campos          : []
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            clientes        : [],
            datosLogin      : null,
            rol             : null,
            imagen          : "",
            tipoCatalogoSAT : [
                {id:1, nombre:"Percepciones",valor:"percepciones"}, 
                {id:2, nombre:"Deducciones", valor:"deducciones"}, 
                {id:3, nombre:"Otros pagos", valor:"otros_pagos"},
                {id:4, nombre:"Informativo", valor:"informativos"}
            ],

            itemsFormulas : [],
            itemsCatalogo   : [],
            tipoSAT         : null,
            tipoSATupdate   : null,
            codigo_sat_id   : null,
            codigo_update   : null,
            limiteSuperior  : 100,
            limiteInferior  : 0,
            maxRules: [
                (v)=> {
                    if (this.conceptoNom.porcentaje > this.limiteSuperior) {
                        return 'Error'
                    }
                }
            ],
            nombre          : null,
            tipo            : null,
            codigo          : null,
            descripcion     : null,
            orden           : null,
            itemsTiposCampos      : [
                {id:1, nombre:"Fecha",valor:"DATE"}, 
                {id:2, nombre:"Entero", valor:"INTEGER"}, 
                {id:3, nombre:"Texto", valor:"STRING"},
                {id:4, nombre:"Decimal", valor:"FLOAT"},
                {id:5, nombre:"Booleano", valor:"BOOL"}
            ],
            itemsCodigos    : [
                {id:1, nombre:"Importe",        valor:"importe"}, 
                {id:2, nombre:"Dias",           valor:"dias"}, 
                {id:3, nombre:"Porcentaje",     valor:"porcentaje"},
                {id:4, nombre:"Número primas",  valor:"numero_primas"},
                {id:5, nombre:"Horas",          valor:"horas"}, 
                {id:6, nombre:"Fecha",          valor:"fecha"},
                {id:7, nombre:"Número periodos",valor:"numero_periodos"},
                {id:8, nombre:"Incluir en el concepto", valor:"incluir"}
            ],
        }
    },
    watch: {  
        tipoSAT: function(val) {
            if(val!=null){
                this.getCatalogoSAT(val);
                if((this.accion =='add') || (this.accion == 'update' && this.tipoSATupdate != this.tipoSAT)){
                    this.getCodigo(val)
                }
                if(this.accion == 'update'){
                    if(this.tipoSATupdate == this.tipoSAT){
                        this.conceptoNom.codigo = this.codigo_update;
                    }
                }
            }
            
        },
        codigo_sat_id:function(val){
            if(this.accion != 'update'){
                let itemCat = this.itemsCatalogo.find(element => element.id == val);
                this.conceptoNom.descripcion = itemCat.descripcion;
            }
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal  = true;
            this.getClientes();
            if(accion == "add"){
                this.tituloModal    = "Registrar Concepto de Nómina";
                this.imagen         = "/static/modal/conceptoNominaCreate.svg";
                
            } else {
                this.tituloModal                = "Actualizar Concepto de Nómina";
                this.imagen                     = "/static/modal/conceptoNominaUpdate.svg";
                this.conceptoNom.id             = data.id;
                this.conceptoNom.cliente_id     = data.cliente_id;
                this.conceptoNom.codigo         = data.codigo;
                this.codigo_update              = data.codigo;
                this.conceptoNom.codigo_sat_id  = data.codigo_sat_id;
                this.codigo_sat_id              = data.codigo_sat_id;
                this.conceptoNom.tipo           = data.tipo;
                this.tipoSAT                    = data.tipo;
                this.tipoSATupdate              = data.tipo;
                this.conceptoNom.descripcion    = data.descripcion;
                this.conceptoNom.porcentaje     = data.porcentaje;
                this.conceptoNom.formula_id     = data.formula_id;
                this.conceptoNom.graba_imss     = data.graba_imss;
                this.conceptoNom.graba_isr      = data.graba_isr;
                this.conceptoNom.graba_impuesto_estatal= data.graba_impuesto_estatal;
                let incidencia_campos  = data.campos;
                incidencia_campos.forEach(element => {
                    const cam = this.itemsTiposCampos.find(el => el.valor == element.tipo);
                    const cod = this.itemsCodigos.find(elem => elem.valor == element.codigo);
                    let campo = {
                        nombre:element.nombre, 
                        tipo: element.tipo, 
                        codigo: element.codigo, 
                        orden: element.orden,
                        tipo_campo:{nombre: cam.nombre}, 
                        codigoNombre : cod.nombre 
                    }
                    this.conceptoNom.campos.push(campo);
                });

            }

            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.conceptoNom    = {
                id              : null,
                cliente_id      : null,
                codigo          : null,
                codigo_sat_id   : null,
                tipo            : null,
                descripcion     : null,
                porcentaje      : 0,
                formula_id      : null,
                graba_imss      : false,
                graba_isr       : false,
                graba_impuesto_estatal: false,
                campos          : []
            }; 
            this.tipoCatalogoSAT = [
                {id:1, nombre:"Percepciones",valor:"percepciones"}, 
                {id:2, nombre:"Deducciones", valor:"deducciones"}, 
                {id:3, nombre:"Otros pagos", valor:"otros_pagos"},
                {id:4, nombre:"Informativo", valor:"informativos"}
            ];
            this.itemsCatalogo = [];
            this.codigo_sat_id = null;
            this.nombre        = null;
            this.tipo          = null;
            this.orden         = null;

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                } 
                this.codigo        = null;
                this.descripcion   = null;
                this.tipoSAT       = null;
            });
        },
        setFilters() {
            this.filters = {AND:[]};
            let codigo = this.codigo;
            let descripcion = this.descripcion;
            let tipo = this.tipoSAT;

            this.isLoading = true;

            if(codigo != null && codigo != undefined && codigo != ""){
                this.filters.AND.push({column:'CODIGO', operator:'ILIKE', value:"%"+codigo+"%"});
            }

            if(descripcion != null && descripcion != undefined && descripcion != ""){
                this.filters.AND.push({column:'DESCRIPCION', operator:'ILIKE', value:"%"+descripcion+"%"});
            }
            
            if(tipo != null && tipo != undefined && tipo != ""){
                this.filters.AND.push({column:'TIPO', operator:'LIKE', value:"%"+tipo+"%"});
            }
            this.paginationData.numberPage = 1;
            this.$apollo.queries.conceptosNominas.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar este concepto de nómina?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiConceptoNomina.delete(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El Concepto de Nómina ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.conceptosNominas.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.conceptoNom.codigo_sat_id = this.codigo_sat_id;
                this.conceptoNom.tipo          = this.tipoSAT;
                
                this.isLoading = true;
                if(this.accion === "add") {
                    apiConceptoNomina.store(this.conceptoNom).then((response) => {
                        Notify.Success("Operación exitosa", "El Concepto de Nomina se guardó satisfactoriamente.");
                        this.$apollo.queries.conceptosNominas.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiConceptoNomina.update(this.conceptoNom).then((response) => {
                        Notify.Success("Operación exitosa", "El Concepto de Nomina se actualizó satisfactoriamente.");
                        this.$apollo.queries.conceptosNominas.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        getClientes(){
            let parametros = { activo:true, paginate:false }
            apiClientes.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Clientes.");
            });
        },
        getCatalogoSAT(tipoCatalogo = null){
        
            switch (tipoCatalogo){
                case "percepciones":
                    let parametros_percepcion = { activo: true, paginate: false, order:'asc'};
                    apiPercepcion.find(parametros_percepcion).then((response)=>{
                        let formatItemsCatalogo = response.data;
                        formatItemsCatalogo.forEach(element => {
                            element["nombre"] = element["clave"] + " - " + element["descripcion"];
                        });
                        this.itemsCatalogo = formatItemsCatalogo;
                    })
                    .catch(err => {
                        
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                    break;
                case "deducciones":
                    let parametros_deduccion = { activo: true, paginate: false, order:'asc'};
                    apiDeduccion.find(parametros_deduccion).then((response)=>{
                        let formatItemsCatalogo = response.data;
                        formatItemsCatalogo.forEach(element => {
                            element["nombre"] = element["clave"] + " - " + element["descripcion"];
                        });
                        this.itemsCatalogo = formatItemsCatalogo;
                    })
                    .catch(err => {
                        
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                    break;
                case "otros_pagos": 
                    let parametros_otro_pago = { activo: true, paginate: false, order:'asc'};
                    apiOtroPago.find(parametros_otro_pago).then((response)=>{
                        let formatItemsCatalogo = response.data;
                        formatItemsCatalogo.forEach(element => {
                            element["nombre"] = element["clave"] + " - " + element["descripcion"];
                        });
                        this.itemsCatalogo = formatItemsCatalogo;
                    })
                    .catch(err => {
                        
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                    break;
                case "informativos":   
                    break;  
                default:
                    break;
            }
        },
        getCodigo(valor){
            let parametros_codigo = { cliente_id: this.conceptoNom.cliente_id, tipo: valor};
            apiConceptoNomina.getCodigo(parametros_codigo).then((response)=>{
                this.conceptoNom.codigo = response.data;
            })
            .catch(err => {
                
                let error = Object.values(err.response.data.error);
                let leyenda = ``;
                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }
                Notify.ErrorToast(leyenda);
                this.isSaving = false;
            });
        },
        agregarCampo(){
            if( this.nombre == null || this.nombre  == "" ||
                this.tipo   == null || this.tipo    == "" || 
                this.codigo == null || this.codigo  == "" ||
                this.orden  == null || this.orden   == "" ){
                   
                Notify.ErrorToast("Favor de agregar todos los campos.");
            }
            else{
                const cam = this.itemsTiposCampos.find(element => element.valor == this.tipo);
                const cod = this.itemsCodigos.find(elem => elem.valor == this.codigo);
                let campo = {nombre:this.nombre, tipo: this.tipo, codigo: this.codigo, orden: this.orden,tipo_campo:{nombre: cam.nombre}, codigoNombre : cod.nombre }
                this.conceptoNom.campos.push(campo);
                this.nombre = null;
                this.tipo   = null;
                this.codigo = null;
                this.orden  = null;
            }
        },
        eliminaCampo(index,id){
            let id_campo = {"id":id};  
            this.conceptoNom.campos.splice(index,1);
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }
    },
    created(){
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        this.$apollo.queries.conceptosNominaFormulas.skip = false;
    },
    mounted(){
        this.$apollo.queries.conceptosNominas.skip = false;
    },
    apollo: {
        conceptosNominas: {
            query       : queries.conceptosNominasTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereCondition : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'codigo',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.conceptosNominas;   
            },
        },
        conceptosNominaFormulas: {
            query       : queriesFormulas.conceptosNominaFormulasTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    numberItems : 10000,
                    numberPage  : 1,
                    fieldOrder  : 'id',
                    Order       : 'ASC'
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.loading    = false;
                this.itemsFormulas  = this.conceptosNominaFormulas.data;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>

